import { For } from 'solid-js';
import { NoHydration } from 'solid-js/web';
import { BreadcrumbItem, Container, Heading, HorizontalRule, Page, Section } from '@troon/ui';
import { useLocation } from '@solidjs/router';
import { Title, Meta } from '@solidjs/meta';
import { Hero } from '../../components/hero/photo';
import { getConfigValue } from '../../modules/config';
import { VideoSection } from '../../components/layouts/video-section';
import type { RouteDefinition } from '@solidjs/router';

export default function AdvantagePage() {
	const loc = useLocation();
	return (
		<>
			<Title>Troon Advantage | Troon Privé Member Programs | Troon</Title>
			<Meta
				name="description"
				content="Qualified members may now directly book resort and daily fee tee times at reduced rates for themselves and up to three guests."
			/>

			<NoHydration>
				<Hero
					src={`${getConfigValue('IMAGE_HOST')}/digital/hero/advantage.jpeg`}
					breadcrumbs={() => (
						<>
							<BreadcrumbItem href="/member-programs">Troon Privé Privileges</BreadcrumbItem>
							<BreadcrumbItem href={loc.pathname}>Troon Advantage</BreadcrumbItem>
						</>
					)}
				>
					<Heading as="h1">Troon Advantage</Heading>
					<p class="text-lg">
						Qualified members may now directly book resort and daily fee tee times at reduced rates for themselves and
						up to three guests.
					</p>
				</Hero>

				<Container>
					<Page>
						<VideoSection
							poster={`${getConfigValue('IMAGE_HOST')}/digital/video/troon-advantage/poster.jpg`}
							src={`${getConfigValue('CDN')}/digital/video/troon-advantage/index.m3u8`}
						>
							<Heading as="h2">Overview</Heading>
							<p>
								Qualified members may now directly book resort and daily fee tee times at reduced rates for themselves
								and up to three guests. Reservations can be made up to sixty (60) days in advance and are subject to
								availability. To book a tee time through Troon Advantage:
							</p>
							<ul class="flex list-disc flex-col gap-4 ps-4">
								<li>
									Access the new Troon Advantage booking link that has been provided to qualified members by the home
									clubs. The location of this live link must be provided by your home club and is not available from the
									host club, on Troon.com or through Troon corporate. Please do not call the host club to book a tee
									time or ask for the link.
								</li>

								<li>
									The new booking link features a searchable database of more than 200 Troon-affiliated resort and daily
									fee destinations around the world, but please feel free to reference the list below for inspiration!
									The contact form previously accessed by clicking Request a Tee Time has been removed, as resort/daily
									fee tee times can only now be booked via the link provided by your home club.
								</li>
							</ul>
						</VideoSection>

						<HorizontalRule />

						<Section>
							<Heading as="h2" class="text-center">
								Domestic locations
							</Heading>
							<ul class="list-disc gap-16 ps-6 sm:columns-2 md:gap-24 lg:columns-3">
								<For each={domestic}>
									{(state) => (
										<li class="mb-4 break-inside-avoid-column">
											<strong>{state.location}</strong>
											<ul class="flex list-disc flex-col gap-1 ps-6">
												<For each={state.facilities}>
													{(facility) => (
														<li>
															{facility.name} | {facility.city}
														</li>
													)}
												</For>
											</ul>
										</li>
									)}
								</For>
							</ul>
						</Section>

						<HorizontalRule />

						<Section>
							<Heading as="h2" class="text-center">
								International locations
							</Heading>
							<ul class="list-disc gap-16 ps-6 sm:columns-2 md:gap-24 lg:columns-3">
								<For each={international}>
									{(state) => (
										<li class="mb-4 break-inside-avoid-column">
											<strong>{state.location}</strong>
											<ul class="flex list-disc flex-col gap-1 ps-6">
												<For each={state.facilities}>
													{(facility) => (
														<li>
															{facility.name} | {facility.city}
														</li>
													)}
												</For>
											</ul>
										</li>
									)}
								</For>
							</ul>
						</Section>
					</Page>
				</Container>
			</NoHydration>
		</>
	);
}

export const route = { info: { hasHero: true } } satisfies RouteDefinition;

const domestic: Array<{ location: string; facilities: Array<{ name: string; city: string }> }> = [
	{
		location: 'Alabama',
		facilities: [
			{ name: 'Gateway Park Golf Course', city: 'Montgomery' },
			{ name: 'Highland Park Golf Course', city: 'Birmingham' },
			{ name: 'Lagoon Park Golf Course', city: 'Montgomery' },
		],
	},
	{
		location: 'Arizona',
		facilities: [
			{ name: 'Ak-Chin Southern Dunes Golf Club', city: 'Maricopa' },
			{ name: 'Arizona National Golf Club', city: 'Tucson&nbsp' },
			{ name: 'Boulders Resort & Spa', city: 'Scottsdale' },
			{ name: 'Dove Valley Ranch Golf Club', city: 'Cave Creek' },
			{ name: 'Eagle Mountain Golf Club', city: 'Fountain Hills' },
			{ name: 'El Conquistador Golf Club', city: 'Tucson' },
			{ name: 'Golf Club of Estrella', city: 'Goodyear' },
			{ name: 'Longbow Golf Club', city: 'Mesa' },
			{ name: 'Lookout Mountain Golf Club', city: 'Phoenix' },
			{ name: 'Papago Golf Club', city: 'Phoenix' },
			{ name: 'Pinetop Lakes Golf & Country Club', city: 'Pinetop' },
			{ name: 'Power Ranch Golf Club', city: 'Gilbert' },
			{ name: 'Quintero Golf Club', city: 'Peoria' },
			{ name: 'Saddlebrooke Two', city: 'Saddlebrooke' },
			{ name: 'Sedona Golf Resort', city: 'Sedona' },
			{ name: 'The Club at Skyline', city: 'Tucson' },
			{ name: 'The Club at Starr Pass', city: 'Tucson' },
			{ name: 'The Phoenician', city: 'Scottsdale' },
			{ name: 'The Westin Kierland Golf Club', city: 'Scottsdale' },
			{ name: 'Troon North Golf Club', city: 'Scottsdale' },
			{ name: 'Wickenburg Ranch Golf & Social Club', city: 'Wickenburg' },
		],
	},
	{ location: 'Arkansas', facilities: [{ name: 'Mystic Creek Golf Club', city: 'El Dorado' }] },
	{
		location: 'California',
		facilities: [
			{ name: 'Aviara Golf Club', city: 'Carlsbad' },
			{ name: 'Bayonet and Black Horse', city: 'Seaside' },
			{ name: 'California Country Club', city: 'Whittier' },
			{ name: 'Classic Club', city: 'Palm Desert' },
			{ name: 'Hiddenbrooke Golf Club', city: 'Vallejo' },
			{ name: 'Indian Wells Golf Resort', city: 'Indian Wells' },
			{ name: 'Maderas Golf Club', city: 'San Diego' },
			{ name: 'Mission Hills North Golf Course', city: 'Rancho Mirage' },
			{ name: 'Monarch Beach Golf Links', city: 'Dana Point' },
			{ name: 'Native Oaks Golf Club', city: 'Valley Center' },
			{ name: 'Shadow Hills Golf Club (North & South)', city: 'Indio' },
			{ name: 'The Golf Club at Moffett Field', city: 'Mountain View' },
			{ name: 'The Grand Golf Club at Fairmont Grand Del Mar', city: 'San Diego' },
			{ name: 'The Ridge Golf Course and Event Center', city: 'Auburn' },
			{ name: 'Tustin Ranch Golf Club', city: 'Tustin' },
			{ name: 'Yocha Dehe Golf Club', city: 'Brooks' },
		],
	},
	{
		location: 'Colorado',
		facilities: [
			{ name: 'Heritage Eagle Bend Golf Club', city: 'Aurora' },
			{ name: 'Rollingstone Ranch Golf Club', city: 'Steamboat Springs' },
			{ name: 'The Ridge at Castle Pines', city: 'Denver' },
		],
	},
	{
		location: 'Delaware',
		facilities: [
			{ name: 'Bayside Resort Golf Club', city: 'Selbyville' },
			{ name: 'Bear Trap Dunes Golf Club', city: 'Ocean View' },
		],
	},
	{
		location: 'Florida',
		facilities: [
			{ name: 'Arlington Ridge Golf Club', city: 'Leesburg' },
			{ name: 'Bent Creek Golf Course', city: 'Jacksonville' },
			{ name: 'Burnt Store Marina Country Club', city: 'Punta Gorda' },
			{ name: 'Cape Royal Golf Club', city: 'Cape Coral' },
			{ name: 'Colony West Country Club', city: 'Tamarac' },
			{ name: 'Eagle Landing Golf Club', city: 'Orange Park' },
			{ name: 'Golf Club of Ocala', city: 'Ocala' },
			{ name: 'Mystic Dunes Golf Club', city: 'Orlando' },
			{ name: 'Saddlebrook Golf & Tennis Resort', city: 'Wesley Chapel' },
			{ name: 'Spanish Wells', city: 'Bonita Springs' },
			{ name: 'St. Johns Golf & Country Club', city: 'St. Augustine' },
			{ name: 'Sun ‘N Lake Golf & Country Club', city: 'Sebring' },
			{ name: 'The Club at Eaglebrooke', city: 'Lakeland' },
			{ name: 'Tiburón Golf Club', city: 'Naples' },
			{ name: 'World Golf Village Resort (King & Bear, Slammer & Squire)', city: 'Augustine' },
		],
	},
	{
		location: 'Georgia',
		facilities: [
			{ name: 'Château Élan Golf Club', city: 'Braselton' },
			{ name: 'Stonebridge Golf Club', city: 'Rome' },
			{ name: 'The Westin Savannah Harbor Golf Resort & Spa', city: 'Savannah' },
		],
	},
	{
		location: 'Hawaii',
		facilities: [
			{ name: 'Ka’anapali Golf Courses', city: 'Lahaina' },
			{ name: 'Kapalua – Bay & Plantation Courses', city: 'Maui' },
			{ name: 'Kapolei Golf Club', city: 'Oahu' },
			{ name: 'Mauna Lani Resort', city: 'Kohala Coast' },
			{ name: 'Pearl Country Club', city: 'Aiea' },
			{ name: 'Priceville Makai Golf Club', city: 'Kauai' },
			{ name: 'The Ocean Course at Hokuala', city: 'Lihue' },
		],
	},
	{
		location: 'Indiana',
		facilities: [
			{ name: 'Crawfordsville', city: 'Crawfordsville' },
			{ name: 'Purgatory Golf Club', city: 'Nobelsville' },
			{ name: 'Thunderbolt Pass Golf Course', city: 'Evansville' },
			{ name: 'Twin Bridges Golf Club', city: 'Danville' },
		],
	},
	{ location: 'Kansas', facilities: [{ name: 'Ironhorse Golf Club', city: 'Leawood' }] },
	{ location: 'Kentucky', facilities: [{ name: 'Kenton County Golf Courses', city: 'Independence' }] },
	{ location: 'Maryland', facilities: [{ name: 'The Preserve at Eisenhower', city: 'Crownsville' }] },
	{ location: 'Michigan', facilities: [{ name: 'Fellows Creek Golf Course', city: 'Canton' }] },
	{ location: 'Mississippi', facilities: [{ name: 'The Refuge Golf Course', city: 'Flowood' }] },
	{ location: 'Missouri', facilities: [{ name: 'The Lodge of Four Seasons', city: 'Lake Ozark' }] },
	{
		location: 'Nevada',
		facilities: [
			{ name: 'Coyote Spring Golf Club', city: 'Las Vegas' },
			{ name: 'Red Hawk Golf and Resort', city: 'Sparks' },
		],
	},
	{ location: 'New Jersey', facilities: [{ name: 'Seaview Hotel & Golf Club', city: 'Galloway Township' }] },
	{ location: 'New Mexico', facilities: [{ name: 'Sandia Golf Club', city: 'Albuquerque' }] },
	{
		location: 'New York',
		facilities: [
			{ name: 'Centennial Golf Club', city: 'Carmel' },
			{ name: 'Roscoe Mountain Club', city: 'Roscoe' },
			{ name: 'The Golf Club at Mansion Ridge', city: 'Monroe' },
			{ name: 'The Monster Golf Club', city: 'Monticello' },
		],
	},
	{
		location: 'North Carolina',
		facilities: [
			{ name: 'Bradford Creek Golf Club', city: 'Greenville' },
			{ name: 'Maggie Valley Golf Club', city: 'Maggie Valley' },
		],
	},
	{ location: 'Ohio', facilities: [{ name: 'Briardale Greens Golf Couse', city: 'Euclid' }] },
	{ location: 'Oklahoma', facilities: [{ name: 'The Club at Forest Ridge', city: 'Broken Arrow' }] },
	{
		location: 'Oregon',
		facilities: [
			{ name: 'Eagle Crest Golf Resort', city: 'Redmond' },
			{ name: 'Pronghorn', city: 'Bend' },
		],
	},
	{
		location: 'Texas',
		facilities: [
			{ name: 'Heritage Ranch Golf & Country Club', city: 'Fairview' },
			{ name: 'Kissing Tree Golf Club', city: 'San Marcos' },
			{ name: 'La Cantera Resort & Spa', city: 'San Antonio' },
			{ name: 'Palmilla Beach Golf Club', city: 'Port Aransas' },
			{ name: 'The Rawls Course at Texas Tech', city: 'Lubbock' },
		],
	},
	{ location: 'Utah', facilities: [{ name: 'Black Desert', city: 'Ivins' }] },
	{
		location: 'Virginia',
		facilities: [
			{ name: 'Forest Green Golf Club', city: 'Triangle' },
			{ name: 'Lake Monticello', city: 'Palmyra' },
			{ name: 'Magnolia Greens Golf Club', city: 'Moseley' },
			{ name: 'Potomac Shores Golf Club', city: 'Potomac Shores' },
			{ name: 'Prince Williams Golf Course', city: 'Nokesville' },
		],
	},
	{ location: 'Washington', facilities: [{ name: 'Gamble Sands', city: 'Brewster' }] },
	{ location: 'West Virginia', facilities: [{ name: 'Oglebay Resorts', city: 'Wheeling' }] },
	{
		location: 'Wisconsin',
		facilities: [
			{ name: 'Johnson Park Golf Course', city: 'Racine' },
			{ name: 'New Berlin Hills Golf Course', city: 'New Berlin' },
		],
	},
];

const international: Array<{ location: string; facilities: Array<{ name: string; city: string }> }> = [
	{
		location: 'Australia',
		facilities: [
			{ name: 'Mollymook Golf Club', city: 'Mollymook' },
			{ name: 'The Grange Golf Club', city: 'Kembla Grange' },
			{ name: 'Brookwater Golf &amp; Country Club', city: 'Brisbane' },
			{ name: 'Pacific Harbour Golf &amp; Country Club', city: 'Bribie Island' },
		],
	},
	{
		location: 'Bahamas',
		facilities: [
			{ name: 'Royal Blue at Baha Mar', city: 'Nassa' },
			{ name: 'Ocean Club', city: 'Paradise Islan' },
		],
	},
	{ location: 'Bahrain', facilities: [{ name: 'Royal Golf Club', city: 'Riffa' }] },
	{ location: 'Cambodia', facilities: [{ name: 'Vattanac Golf Resort', city: 'Phnom Penh' }] },
	{ location: 'Costa Rica', facilities: [{ name: 'Reserva Conchal Club', city: 'Cabo Velas' }] },
	{ location: 'Czech Republic', facilities: [{ name: 'PGA National Czech Republic', city: 'Prague' }] },
	{
		location: 'Egypt',
		facilities: [
			{ name: 'Madinaty Golf Club', city: 'Second New Cairo' },
			{ name: 'The Allegria', city: 'Sheikh Zayed City, Cairo' },
		],
	},
	{ location: 'England', facilities: [{ name: 'The Grove', city: 'Hertfordshire' }] },
	{ location: 'Greece', facilities: [{ name: 'Costa Navarino', city: 'Messinia' }] },
	{ location: 'India', facilities: [{ name: 'Prestige Golfshire Club', city: 'Bangalore' }] },
	{
		location: 'Malaysia',
		facilities: [{ name: 'Els Club Desaru Coast Ocean &amp; Valley Course', city: 'Johor Bahru' }],
	},
	{ location: 'Mexico', facilities: [{ name: 'Palmilla Golf Club', city: 'San Jose del Cabo' }] },
	{
		location: 'Oman',
		facilities: [
			{ name: 'Al Mouj', city: 'Muscat' },
			{ name: 'LA VIE Club', city: 'Muscat' },
		],
	},

	{
		location: 'Puerto Rico',
		facilities: [
			{ name: 'Bahia Beach Resort &amp; Golf Club', city: 'Rio Grande' },
			{ name: 'Rio Mar Country Club', city: 'Rio Grande' },
		],
	},
	{ location: 'Saudi Arabia', facilities: [{ name: 'Royal Greens Golf &amp; Country Club', city: 'Al Murooj' }] },
	{
		location: 'Spain',
		facilities: [
			{ name: 'Las Colinas Golf &amp; Country Club', city: 'Alicante' },
			{ name: 'Finca Cortesin', city: 'Malaga' },
		],
	},
	{ location: 'Sri Lanka', facilities: [{ name: 'Victoria Golf Resort', city: 'Digana' }] },
	{
		location: 'United Arab Emirates',
		facilities: [
			{ name: 'Al Hamra Golf Club', city: 'Ras Al Khaimah' },
			{ name: 'Al Zorah Golf Club', city: 'Ajman' },
			{ name: 'Arabian Ranches Golf Club', city: 'Dubai' },
			{ name: 'Dubai Hills Golf Club', city: 'Dubai' },
			{ name: 'Montgomerie Golf Club', city: 'Dubai' },
			{ name: 'The Els Club, Dubai', city: 'Dubai' },
			{ name: 'The Track, Meydan Golf', city: 'Dubai' },
		],
	},
];
